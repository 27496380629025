import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const CSCC = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview title="Study Overview" description="Multicenter, multicohort, nonrandomized, open-label trial that included 2 cohorts that were analyzed separately: 1 for recurrent or metastatic cSCC and 1 for locally advanced cSCC" data={[
            [
                {
                    title: 'Patient Population',
                    description: `
                        <p>Locally advanced or recurrent/metastatic cSCC (n=159)</p>
                        <ul>
                            <li>Patients with recurrent or metastatic cSCC (n=105)</li>
                            <li>Patients with locally advanced cSCC (n=54)</li>
                        </ul>
                        <p>Measurable disease (RECIST v1.1)<sup>1</sup></p>
                        <p>ECOG PS 0 or 1</p>
                    `,
                    type: 'box-gradient'
                }
            ]
        ]} />
    
        <StudyDesignArms title="Study Cohorts" arms={[
            {
                title: "Recurrent or metastatic cSCC (n=105)",
                data: [
                    [
                        {
                            title: 'Initial Treatment',
                            description: `KEYTRUDA, 200&nbsp;mg IV (Q3W)`,
                            type: 'box-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `<p>Treatment continued until disease progression, unacceptable toxicity, or up to 24 months<sup>g</sup></p>
                                        <p>Assessment of tumor status was performed every 6 weeks for the first year, then every 9 weeks during the second year</p>`
                        }
                    ]
                ]
            },
            {
                title: "Locally advanced cSCC (n=54)",
                data: [
                    [
                        {
                            title: 'Initial Treatment',
                            description: `KEYTRUDA, 200&nbsp;mg IV (Q3W)`,
                            type: 'box-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `<p>Treatment continued until disease progression, unacceptable toxicity, or up to 24 months<sup>g</sup></p>
                                        <p>Assessment of tumor status was performed every 6 weeks for the first year, then every 9 weeks during the second year</p>`
                        }
                    ]
                ]
            }
        ]} />
    
        <StudyCopyBlock 
            title="End Points"
            description={`
                <p>Major efficacy outcome measures were objective response rate
                (ORR) and duration of response (DOR) as assessed by BICR
                according to RECIST v1.1, modified to follow the maximum of 10
                target lesions and a maximum of 5 target lesions per organ.</p>
            `} 
        />
    
        <StudyCopyBlock 
            title="Patient Characteristics"
            description={`
                <p>The trial excluded patients with autoimmune disease or a medical
                condition that required immunosuppression.</p>
                <p>Patients with recurrent or metastatic cSCC (n=105)</p>
                <ul>           
                    <li>Median age: 72 years</li>         
                    <li>Age range: 29-95 years</li>
                    <li>Male: 76%</li>
                    <li>White: 70%</li>
                    <li>ECOG PS 0: 34%</li>
                    <li>Prior radiation therapy: 73%</li>
                </ul>
                <p>Patients with locally advanced cSCC (n=54)</p>
                <ul>       
                    <li>Median age: 76 years</li>
                    <li>Age range: 35-95 years</li>   
                    <li>Male: 72%</li>
                    <li>White: 83%</li>
                    <li>ECOG PS 0: 41%</li>
                    <li>Prior radiation therapy: 63%</li>
                </ul>
            `} 
        />
    
        <StudyCopyBlock 
            footnotes={[
                {
                    label:'g.',
                    text: `
                        Patients with initial radiographic disease progression could
                        receive additional doses of KEYTRUDA during confirmation of
                        progression unless disease progression was symptomatic, rapidly
                        progressive, required urgent intervention, or occurred with a
                        decline in performance status.
                    `
                }
            ]}
            definitions={`
                BICR = blinded independent central review; ECOG PS = Eastern Cooperative Oncology Group performance status; IV = intravenously; Q3W = every 3 weeks; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1.
            `} 
        />
    </ComponentWrapper>
);

export default CSCC;
